import React, {useEffect} from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router"

import {Mini} from "@dadoagency/gatsby-plugin-trustpilot-widget";

import { AppendScript } from "gatsby-theme-starberry-lomondgroup/src/common/utils/site_utlis";

const ReviewRatings = (props) => {
    
    const location = useLocation();

    //console.log("location ==>", location);


    // useEffect(()=>{
    //     //console.log('comes_on')

    //     if(typeof window !== "undefined"){
    //         AppendScript("https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js", "", () => {});
    //     }
      
    // })


    return(
        <div className="ratings">
            {/* <div className="rating-img">
                <img src={allAgentImg} alt="rating-img" />
               
            </div>
            <div className="reviews">
            <div id={props?.id ? props.id : `wd_id`}></div>
                4.6/5 <Link to="/" className="sm-reviews">53 Reviews</Link>
            </div> */}
            {/* <div className="trustpilot-widget" data-locale="en-GB" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="51bc122300006400053c1471" data-style-height="110px" data-style-width="100%" data-theme="dark" data-font-family="Montserrat">
                <a href="https://uk.trustpilot.com/review/thornleygroves.co.uk" target="_blank" rel="noopener">Trustpilot</a>
            </div> */}

            <Mini 
                language="en"
                culture="US"
                theme="dark"
                width="100%"
                height="110px"
                stars="1,2,3,4,5"
            />

        </div>
    )
}
export default ReviewRatings;